import { Box, Breadcrumbs, Divider, Grid, Link, Modal, Paper, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams } from "react-router-dom";
import { DataModel } from "../models/DataModel";
import { ConInformative, Condition } from "../models/AnswerModel";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { conditionTypes } from '../data/ConditionTypes';
import DropdownInput from '../components/forms/DropdownInput';
import ConditionalLineComponent from '../components/Conditions';
import { ConAnswer } from '../models/ConAnswerModel';
import { useEndpoints } from '../utils/EndpointContext';

type Props = {}
type QuestionInformativeParams = {
    qid: string;
    iid: string;
};

const QuestionInformativePage: React.FC = (props: Props) => {
    
    const endPoints = useEndpoints();
    const navigate = useNavigate();

    let { qid, iid } = useParams<QuestionInformativeParams>();
    const informativeIndex = (iid) ? parseInt(iid!) : 0;
    
    const defaultBaseData: DataModel = {
        Informatives: [],
        Companies: [],
        Answers: [],
        DateCreated: '',
        CreatedBy: '',
        Questions: []
    };

    const defaultConAnswer: ConAnswer = {
        QuestionId: '',
        AnswersSet: [],
        Informatives: []
    };

    const defaultConInformative: ConInformative = {
        InformativeId: [],
        Conditions: [],
        Type: "default",
        NestedInformatives: []
    }

    const defaultCondition: Condition = {
        Key: '',
        Value: ''
    }

    const [baseData, setBaseData] = useState<DataModel>(defaultBaseData);
    const [conAnswerData, setConAnswerData] = useState<ConAnswer>(defaultConAnswer);
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [data, setData] = useState<ConInformative>(defaultConInformative);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '80%',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll'
    };
    
    useEffect(() => {
        const activeData = localStorage.getItem('activeData');

        axios.get(`${endPoints.questionEditorRead}&blobName=${activeData}`)
        .then(response => {
            setBaseData(response.data);
            let conAnswer = response.data.Answers.find((element : ConAnswer) => element.QuestionId === qid);
            setQuestionIndex(response.data.Answers.indexOf(conAnswer));
            setConAnswerData(conAnswer);
            setData(conAnswer.Informatives[informativeIndex]);
        });
    }, []);

    const handleSave = () => {
        const activeData = localStorage.getItem('activeData');
        baseData.Answers[questionIndex].Informatives[informativeIndex] = data;
        axios.post(`${endPoints.questionEditorUpdate}&blobName=${activeData}`, baseData);
    };

    const handleConditionKeyChange = useCallback((value: string, conditionIndex: number) => {
        const newData = {...data};
        newData.Conditions[conditionIndex].Key = value;
        setData(newData);
    }, [data]);

    const handleConditionValueChange = useCallback((value: string, conditionIndex: number) => {
        const newData = {...data};
        newData.Conditions[conditionIndex].Value = value;
        setData(newData);
    }, [data]);

    const handleNestedInformativeAdd = () => {
        const newData = { ...data };

        if (newData.NestedInformatives == null) { newData.NestedInformatives = []; }

        newData.NestedInformatives = [...newData.NestedInformatives, defaultConInformative];
        setData(newData);
        handleSave();
    };

    const handleConditionAdd = () => {
        const newData = {...data};
        newData.Conditions = [...newData.Conditions, defaultCondition];
        setData(newData);
        handleSave();
    }

    const handleConditionRemove = (index: number) => {
        const newData = {...data};
        newData.Conditions.splice(index, 1);
        setData(newData);
        handleSave();
    };

    const handleInformativeIdAdd = (informativeId: string) => {
        const newData = {...data};
        newData.InformativeId = [...newData.InformativeId, informativeId];
        setData(newData);
        handleSave();
    }

    const handleInformativeIdRemove = (index: number) => {
        const newData = {...data};
        newData.InformativeId.splice(index, 1);
        setData(newData);
        handleSave();
    }

    const handleTypeChange = useCallback((value: string) => {
        const newData = {...data};
        newData.Type = value;
        setData(newData);
        handleSave();
    }, [data]);

    const renderNestedInformativeAddButton = () => {
        if (data.Type !== 'nested') {
            return (<></>);
        }

        return (<Button variant="contained" color='secondary' onClick={handleNestedInformativeAdd}>Add Nested Informative <CreateIcon /></Button>);
    }

    const renderInformativeAddButton = () => {
        if (data.Type == 'nested') {
            return (<></>);
        }

        return (<Button variant="contained" color='secondary' onClick={handleOpen}>Add Informative <CreateIcon /></Button>);
    }

    const handleNestedInformativeEdit = (informativeId: number, nestedInformativeIndex: number) => {
        navigate(`/questions/${conAnswerData.QuestionId}/answers/informative/${informativeId}/nested/${nestedInformativeIndex}`);
    };

    const handleNestedInformativeRemove = (index: number) => {
        const newData = { ...data };
        newData.NestedInformatives?.splice(index, 1);
        setData(newData);
        handleSave();
    };

    const renderInformative = (informativeId: string) => {
        return baseData.Informatives.map((informative) => {
            if(informative.Id === informativeId){
                return informative.Content;
            }

            return (<></>);
        });
    }

    const renderConditionAddButton = () => {
        return (<Button variant="contained" color='secondary' onClick={handleConditionAdd}>Add Condition <CreateIcon /></Button>);
    }

    const renderNestedInformatives = () => {

        if (data.Type !== 'nested') {
            return (<></>);
        }

        return (
            <Box sx={{ m: 2 }}>
                <Stack spacing={2}>
                    {data.NestedInformatives?.map((nestedInformative, nestedInformativeIndex) => (
                        <Paper elevation={2} style={{ backgroundColor: "#FAFAFA" }} key={nestedInformativeIndex}>
                            <Stack key={nestedInformativeIndex}>
                                <Box sx={{ m: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={10}>
                                        {nestedInformative.InformativeId.map((informativeId, informativeIdIndex) => (
                                            <Stack key={informativeIdIndex}>
                                                <Box sx={{ m: 2 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={11}>
                                                            <Typography><strong>({informativeId})</strong> {renderInformative(informativeId)}</Typography>
                                                        </Grid>
                                                        <Grid display={'flex'} justifyContent={'flex-end'} item xs={1}></Grid>
                                                    </Grid>
                                                </Box>
                                                <Divider variant="middle" />
                                            </Stack>
                                        ))}
                                        </Grid>
                                        <Grid display={'flex'} item xs={2} justifyContent="flex-end">
                                            <Button color='error' onClick={() => handleNestedInformativeRemove(nestedInformativeIndex)}> <DeleteIcon /></Button>
                                            <Button variant="contained" color='secondary' onClick={() => handleNestedInformativeEdit(informativeIndex, nestedInformativeIndex)}><EditIcon /></Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider variant="middle" />
                            </Stack>
                        </Paper>
                    ))}
                </Stack>
            </Box>
        )
    }

    const renderInformatives = () => {
        if (data.Type === 'nested') {
            return (<></>);
        }

        return baseData.Informatives.map((informative, index) => {
            if(!data.InformativeId.includes(informative.Id))
            {
                return (
                    <Stack>
                        <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <Typography><strong>({informative.Id})</strong> {informative.Content}</Typography>
                                </Grid>
                                <Grid display={'flex'} justifyContent={'flex-end'} item xs={1}>
                                    <Button variant='contained' onClick={() => handleInformativeIdAdd(informative.Id)}> <CreateIcon /></Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                   );
            }
            
            return (<></>);
        });
    }

    const renderConditions = () => {
        return (
            <ConditionalLineComponent data={data} onHandleConditionKeyChange={handleConditionKeyChange} onHandleConditionValueChange={handleConditionValueChange} onHandleConditionRemove={handleConditionRemove} />
        )
    }

    return (
        <div>
            {data && conAnswerData ?
            <>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '1em'}}>
                    <Grid item xs={6}>
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link underline="hover" onClick={() => { navigate(`/questions`) }}>Questions</Link>
                            <Typography color="text.primary">Question {conAnswerData.QuestionId}</Typography>
                            <Link underline="hover" onClick={() => { navigate(`/questions/${conAnswerData.QuestionId}/answers`) }}>Answers</Link>
                            <Typography color="text.primary">Informative</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                {renderNestedInformativeAddButton()}
                                {renderConditionAddButton()}
                                {renderInformativeAddButton()}
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Answer <SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Paper>
                    {data.InformativeId.map((informativeId, informativeIdIndex) => (
                        <Stack key={informativeIdIndex}>
                            <Box sx={{ m: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={11}>
                                        <Typography><strong>({informativeId})</strong> {renderInformative(informativeId)}</Typography>
                                    </Grid>
                                    <Grid display={'flex'} justifyContent={'flex-end'} item xs={1}>
                                        <Button color='error' onClick={() => handleInformativeIdRemove(informativeIdIndex)}> <DeleteIcon /></Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider variant="middle" />
                        </Stack>
                    ))}
                </Paper>
                <Paper style={{marginTop: "1em"}}>
                    <DropdownInput label='Type' fieldName={`type`} value={data.Type} items={conditionTypes} onChange={(value) => handleTypeChange(value)} />
                    {renderConditions()}
                    {renderNestedInformatives()}
                </Paper>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginTop: '1em'}}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                {renderNestedInformativeAddButton()}
                                {renderConditionAddButton()}
                                {renderInformativeAddButton()}
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Answer <SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    >
                    <Box sx={style}>
                        {renderInformatives()}
                    </Box>
                </Modal>
            </> : <>Loading Question Informative</>}
        </div>
    );
}

export default QuestionInformativePage;