import DashboardPage from './pages/DashboardPage';
import InformativesPage from './pages/InformativesPage';
import CompaniesPage from './pages/CompaniesPage';
import QuestionsPage from './pages/QuestionsPage';
import { AppRoute } from './models/common/AppRoute';
import QuestionPage from './pages/QuestionPage';
import ConAnswerPage from './pages/ConAnswerPage';
import AnswerPage from './pages/AnswerPage';
import QuestionInformativePage from './pages/QuestionInformativePage';
import NestedAnswerPage from './pages/NestedAnswerPage';
import QuestionNestedInformativePage from './pages/QuestionNestedInformativePage';

const AppRoutes : AppRoute[] = [
    {
        path: "/",
        name: "Dashboard",
        component: DashboardPage,
        isVisible: false
    },
    {
        path: "/questions",
        name: "Questions",
        component: QuestionsPage,
        isVisible: true
    },
    {
        path: "/questions/:qid",
        name: "Question",
        component: QuestionPage,
        isVisible: false
    },
    {
        path: "/questions/:qid/answers",
        name: "Answers",
        component: ConAnswerPage,
        isVisible: false
    },
    {
        path: "/questions/:qid/answers/:asid/:aid",
        name: "Answer",
        component: AnswerPage,
        isVisible: false
    },
    {
        path: "/questions/:qid/answers/:asid/:aid/nested/:naid",
        name: "Answer",
        component: NestedAnswerPage,
        isVisible: false
    },
    {
        path: "/questions/:qid/answers/informative/:iid",
        name: "Question Informative",
        component: QuestionInformativePage,
        isVisible: false
    },
    {
        path: "/questions/:qid/answers/informative/:iid/nested/:nid",
        name: "Question Nested Informative",
        component: QuestionNestedInformativePage,
        isVisible: false
    },
    {
        path: "/informatives",
        name: "Informatives",
        component: InformativesPage,
        isVisible: true
    },
    {
        path: "/companies",
        name: "Companies",
        component: CompaniesPage,
        isVisible: true
    }
];

export default AppRoutes;