import { DropdownItem } from "../components/forms/DropdownField";

export const conditionTypes : DropdownItem[] = [
    {
        key: "default",
        value: "Default",
        description: "When there are either no conditions required or no conditions match, this is a default response"
    },
    {
        key: "always",
        value: "Always",
        description: "Will always show regardless of conditions"
    },
    {
        key: "or",
        value: "Any",
        description: "When any conditions match"
    },
    {
        key: "and",
        value: "All",
        description: "One or more conditions must all match"
    },
    {
        key: "notand",
        value: "Not Equal",
        description: "When one or more conditions must not equal a value"
    },
    {
        key: "nested",
        value: "Nested",
        description: "When a set of conditions are equal to their values but require further conditional checks"
    }
];

export function MapToConditionType(key : string) : string {
    let type = "default";
    
    conditionTypes.forEach((item, index) => {
        if(item.key === key){
            type = item.value;
        }
    });

    return type;
}