import { Alert, Box, Breadcrumbs, Divider, Grid, Link, Paper, Snackbar, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CreateIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams } from "react-router-dom";
import { DataModel } from "../models/DataModel";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { conditionTypes } from '../data/ConditionTypes';
import DropdownInput, { DropdownItem } from '../components/forms/DropdownInput';
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Placeholder from '../components/rte/Placeholder';
import ConditionalLineComponent from '../components/Conditions';
import { getQuestions } from '../data/QuestionAndAnswerService';
import { Answer, Condition } from '../models/AnswerModel';
import { ConAnswer } from '../models/ConAnswerModel';
import TextInput from '../components/forms/TextInput';
import { useEndpoints } from '../utils/EndpointContext';

type Props = {}
type AnswerParams = {
    qid: string;
    asid: string;
    aid: string;
    naid: string;
};

const AnswerPage: React.FC = (props: Props) => {

    const endPoints = useEndpoints();
    const navigate = useNavigate();

    let { qid, asid, aid, naid } = useParams<AnswerParams>();
    const answerSetIndex = (asid) ? parseInt(asid!) : 0;
    const answerIndex = (aid) ? parseInt(aid!) : 0;
    const nestedAnswerIndex = (naid) ? parseInt(naid!) : 0;

    const conditionKeysArray: DropdownItem[] = [];

    const defaultBaseData: DataModel = {
        Informatives: [],
        Companies: [],
        Answers: [],
        DateCreated: '',
        CreatedBy: '',
        Questions: []
    };

    const defaultConAnswer: ConAnswer = {
        QuestionId: '',
        AnswersSet: [],
        Informatives: []
    };

    const defaultAnswer: Answer = {
        Answer: "",
        Type: "default",
        Conditions: [],
        Summary: ''
    }

    const defaultCondition: Condition = {
        Key: '',
        Value: ''
    }

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [baseData, setBaseData] = useState<DataModel>(defaultBaseData);
    const [conAnswerData, setConAnswerData] = useState<ConAnswer>(defaultConAnswer);
    const [data, setData] = useState<Answer>(defaultAnswer);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [questionIndex, setQuestionIndex] = useState<number>(0);

    useEffect(() => {
        const activeData = localStorage.getItem('activeData');

        axios.get(`${endPoints.questionEditorRead}&blobName=${activeData}`)
            .then(response => {
                setBaseData(response.data);
                let conAnswer = response.data.Answers.find((element : ConAnswer) => element.QuestionId === qid);
                setQuestionIndex(response.data.Answers.indexOf(conAnswer));
                setConAnswerData(conAnswer);
                let answser: Answer = conAnswer.AnswersSet[answerSetIndex].Answers[answerIndex].NestedAnswers[nestedAnswerIndex];
                setData(answser);
                setEditorState(EditorState.createWithContent(stateFromHTML(answser.Answer)));
            });
    }, []);

    const handleEditorChange = (state: EditorState) => {
        setEditorState(state);
        handleAnswerChange(stateToHTML(state.getCurrentContent()));
    }

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleSave = () => {
        const activeData = localStorage.getItem('activeData');
        baseData.Answers[questionIndex].AnswersSet[answerSetIndex].Answers[answerIndex].NestedAnswers![nestedAnswerIndex] = data;
        axios.post(`${endPoints.questionEditorUpdate}&blobName=${activeData}`, baseData);
        setOpenAlert(true);
    };

    const handleConditionAdd = () => {
        const newData = { ...data };
        newData.Conditions = [...newData.Conditions, defaultCondition];
        setData(newData);
        handleSave();
    };

    const handleAnswerChange = useCallback((value: string) => {
        const newData = { ...data };
        newData.Answer = value;
        setData(newData);
    }, [data]);

    const handleSummaryChange = useCallback((value: string) => {
        const newData = { ...data };
        newData.Summary = value;
        setData(newData);
    }, [data]);

    const handleTypeChange = useCallback((value: string) => {
        const newData = { ...data };
        newData.Type = value;
        setData(newData);
        handleSave();
    }, [data]);

    const handleConditionKeyChange = useCallback((value: string, conditionIndex: number) => {
        const newData = { ...data };
        newData.Conditions[conditionIndex].Key = value;
        newData.Conditions[conditionIndex].Value = "";
        setData(newData);
    }, [data]);

    const handleConditionValueChange = useCallback((value: string, conditionIndex: number) => {
        const newData = { ...data };
        newData.Conditions[conditionIndex].Value = value;
        setData(newData);
    }, [data]);

    const handleConditionRemove = (index: number) => {
        const newData = { ...data };
        newData.Conditions.splice(index, 1);
        setData(newData);
        handleSave();
    };

    const renderAnswer = () => {

        if (data.Type === 'nested') {
            return (<></>);
        }

        return (<Paper>
            <Typography sx={{ mb: 2 }}>Full Answer</Typography>
            <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={handleEditorChange}
                toolbar={{
                    options: ['inline', 'remove', 'history'],
                }}
                toolbarCustomButtons={[
                    <Placeholder
                        editorState={editorState}
                        onChange={handleEditorChange}
                        options={[...getQuestions(), 'waterSupplierAddress', 'waterSupplierCompanyName', 'sewerageSupplierAddress', 'sewerageSupplierCompanyName']} />]}
            />
        </Paper>);
    }

    const renderConditionAddButton = () => {
        return (<Button variant="contained" color='secondary' onClick={handleConditionAdd}>Add Condition <CreateIcon /></Button>);
    }

    const renderConditions = () => {
        return (
            <ConditionalLineComponent data={data} onHandleConditionKeyChange={handleConditionKeyChange} onHandleConditionValueChange={handleConditionValueChange} onHandleConditionRemove={handleConditionRemove} />
        )
    }

    const renderNestedAnswers = () => {

        if (data.Type !== 'nested') {
            return (<></>);
        }

        return (
            <Box sx={{ m: 2 }}>
                <Stack spacing={2}>
                    {data.NestedAnswers?.map((nestedAnswer, nestedAnswerIndex) => (
                        <Paper elevation={2} style={{ backgroundColor: "#FAFAFA" }} key={nestedAnswerIndex}>
                            <Stack key={nestedAnswerIndex}>
                                <Box sx={{ m: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={10}>
                                            <Typography>{nestedAnswer.Answer}</Typography>
                                        </Grid>
                                        <Grid display={'flex'} item xs={2} justifyContent="flex-end">
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider variant="middle" />
                            </Stack>
                        </Paper>
                    ))}
                </Stack>
            </Box>
        )
    }

    return (
        <div>
            {data && conAnswerData ?
                <>
                    <Grid container spacing={2} justifyContent="flex-end" style={{ marginBottom: '1em' }}>
                        <Grid item xs={6}>
                            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                                <Link underline="hover" onClick={() => { navigate(`/questions`) }}>Questions</Link>
                                <Typography color="text.primary">Question {conAnswerData.QuestionId}</Typography>
                                <Link underline="hover" onClick={() => { navigate(`/questions/${conAnswerData.QuestionId}/answers`) }}>Answers</Link>
                                <Link underline="hover" onClick={() => { navigate(`/questions/${conAnswerData.QuestionId}/answers/${answerSetIndex}/${answerIndex}`) }}>Answer</Link>
                                <Typography color="text.primary">Nested Answer</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end" className="tools">
                                <Stack spacing={2} direction="row">
                                    {renderConditionAddButton()}
                                    <Button variant="contained" color='primary' onClick={handleSave}>Save Answer <SaveIcon /></Button>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Paper>
                        <Stack>
                            <Box sx={{ m: 2 }}>
                                <DropdownInput label='Type' fieldName={`type`} value={data.Type} items={conditionTypes} onChange={(value) => handleTypeChange(value)} />
                            </Box>
                            <Box sx={{ m: 2 }}>
                                <TextInput label='Summary' fieldName={`Summary`} value={data.Summary} onChange={(value) => handleSummaryChange(value)} />
                            </Box>
                            <Box sx={{ m: 2 }}>
                                {renderAnswer()}
                            </Box>
                            <Divider variant="middle" />
                            {renderConditions()}
                            {renderNestedAnswers()}
                        </Stack>
                    </Paper>
                    <Grid container spacing={2} justifyContent="flex-end" style={{ marginTop: '1em' }}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end" className="tools">
                                <Stack spacing={2} direction="row">
                                    {renderConditionAddButton()}
                                    <Button variant="contained" color='primary' onClick={handleSave}>Save Answer <SaveIcon /></Button>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                        <Alert severity="success" sx={{ width: '100%' }}>
                            Answer updated successfully.
                        </Alert>
                    </Snackbar>
                </> : <>Loading Answer</>}
        </div>
    );
}

export default AnswerPage;