import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

export type DropdownItem = {
    key: string,
    value: string,
    description: string | null
}

interface DropdownInputProps {
    fieldName: string;
    label?: string;
    value: string;
    items: DropdownItem[];
    disabled?: boolean;
    onChange: (value: string) => void;
}

const DropdownInput: React.FC<DropdownInputProps> = React.memo(({ items, disabled, label, fieldName, value, onChange }) => {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    const renderItems = () => {
        return items.map((item, index) => {
            if(item.description){
                return (<MenuItem key={index} value={item.key}>{item.value} ({item.description})</MenuItem>);
            }else{
                return (<MenuItem key={index} value={item.key}>{item.value}</MenuItem>);
            }

        });
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={label}
                onChange={e => onChange(e.target.value)}
                name={fieldName}
            >
                {renderItems()}
            </Select>
        </FormControl>
    );
});

export default DropdownInput;